import React, { useState } from 'react'
import { LazyImage } from 'components/atoms/Image'

import { ReactComponent as ArrowLeft } from 'assets/icons/arrowLeft.svg'
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'
import { ReactComponent as Close } from 'assets/icons/close.svg'

import { ImageType } from 'types/image'

import { Container, ViewContainer } from 'components/atoms/Container'
import {
  CenterImageWrapper,
  CloseButton,
  IconWraper,
  ImageView,
  ImageViewContent,
  ImageWraper,
  StyledIconButton,
  ImageContainer,
  FadeOutImageWrapper,
} from './ImageCarusela.styles'

type ImageCaruselaProps = {
  images: Array<ImageType>
  selectedImage: number
  setSelectedImage: React.Dispatch<React.SetStateAction<number>>
  handelShowSlider: () => void
}

export const ImageCarusela = ({
  images,
  selectedImage,
  setSelectedImage,
  handelShowSlider,
}: ImageCaruselaProps) => {
  const [previousImage, setPreviousImage] = useState<number | null>(null)
  const [isAnimating, setIsAnimating] = useState<boolean>(false)

  const handleSlideChange = (action: 'next' | 'prev') => {
    let newIndex = selectedImage

    switch (action) {
      case 'next':
        newIndex = selectedImage + 1

        setIsAnimating(true)
        setPreviousImage(selectedImage)
        setSelectedImage(newIndex >= images.length ? 0 : newIndex)

        setTimeout(() => {
          setIsAnimating(false)
          setPreviousImage(null)
        }, 1000)
        break
      case 'prev':
        newIndex = selectedImage - 1

        setIsAnimating(true)
        setPreviousImage(selectedImage)
        setSelectedImage(newIndex < 0 ? images.length - 1 : newIndex)

        setTimeout(() => {
          setIsAnimating(false)
          setPreviousImage(null)
        }, 1000)
        break
      default:
        setSelectedImage(selectedImage)
    }
  }

  return (
    <ImageView>
      <ImageViewContent
        padding={[100, 80]}
        margin={[0, 'auto']}
        onClick={() => handelShowSlider()}
      >
        <ViewContainer breakpoint="lg">
          <IconWraper
            onClick={(e) => {
              e.stopPropagation()
              if (!isAnimating) {
                handleSlideChange('prev')
              }
            }}
          >
            <StyledIconButton disabled={isAnimating}>
              <ArrowLeft />
            </StyledIconButton>
          </IconWraper>
        </ViewContainer>

        <ViewContainer initialHidden breakpoint="sm">
          <CloseButton green onClick={() => handelShowSlider()}>
            <Close />
          </CloseButton>
        </ViewContainer>

        <ImageContainer>
          <ImageWraper onClick={(e) => e.stopPropagation()}>
            <ViewContainer breakpoint="sm">
              <CloseButton green onClick={() => handelShowSlider()}>
                <Close />
              </CloseButton>
            </ViewContainer>

            {previousImage !== null && (
              <FadeOutImageWrapper>
                <LazyImage
                  src={images[previousImage].src}
                  alt={images[previousImage].alt!}
                  fill
                  objectFit="cover"
                />
              </FadeOutImageWrapper>
            )}
            <CenterImageWrapper>
              <LazyImage
                src={images[selectedImage].src!}
                alt={images[selectedImage].alt!}
                fill
                objectFit="cover"
              />
            </CenterImageWrapper>
          </ImageWraper>
        </ImageContainer>

        <ViewContainer breakpoint="lg">
          <IconWraper
            onClick={(e) => {
              e.stopPropagation()
              if (!isAnimating) {
                handleSlideChange('next')
              }
            }}
          >
            <StyledIconButton disabled={isAnimating}>
              <ArrowRight />
            </StyledIconButton>
          </IconWraper>
        </ViewContainer>

        <ViewContainer initialHidden breakpoint="lg">
          <Container flexRow justifyContent="center">
            <IconWraper
              onClick={(e) => {
                e.stopPropagation()
                if (!isAnimating) {
                  handleSlideChange('prev')
                }
              }}
            >
              <StyledIconButton disabled={isAnimating}>
                <ArrowLeft />
              </StyledIconButton>
            </IconWraper>
            <IconWraper
              onClick={(e) => {
                e.stopPropagation()
                if (!isAnimating) {
                  handleSlideChange('next')
                }
              }}
            >
              <StyledIconButton disabled={isAnimating}>
                <ArrowRight />
              </StyledIconButton>
            </IconWraper>
          </Container>
        </ViewContainer>
      </ImageViewContent>
    </ImageView>
  )
}
