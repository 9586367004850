import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { ImageType } from 'types/image'

import { Container } from 'components/atoms/Container'
import { Pagination } from 'components/atoms/Pagination'
import { ImagesContainer } from './ImagesContainer'

const SearchWraper = styled(Container)`
  max-width: none;
  box-sizing: border-box;

  ${media.xl.max} {
    padding-left: 16px;
    padding-right: 16px;
  }
  ${media.sm.max} {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
`

const ContentWraper = styled(Container)`
  max-width: none;
  background-color: #f3f3f3;

  ${media.sm.max} {
    padding: 48px 24px;
  }
`

const Content = styled(Container)`
  max-width: 1136px;

  /* position: relative; */

  display: flex;
  flex-direction: column;
  gap: 40px;
`

const NavigationContainer = styled.div`
  width: fit-content;
  margin-left: auto;

  ${media.sm.max} {
    padding-right: 16px;
  }
`

const IMAGES_PER_VIEW = 9

export const Gallery = ({
  images,
  handelSlider,
}: {
  images: Array<ImageType>
  handelSlider: (index: number) => void
}) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const numberOfPages = Math.ceil(images.length / IMAGES_PER_VIEW)

  const onPageChange = (number: number) => {
    setCurrentPage(number)
  }

  return (
    <SearchWraper padding={[0, 32, 100, 32]}>
      <ContentWraper padding={[100, 40]}>
        <Content margin={[0, 'auto']}>
          <ImagesContainer
            images={images.slice(
              (currentPage - 1) * IMAGES_PER_VIEW,
              (currentPage - 1) * IMAGES_PER_VIEW + IMAGES_PER_VIEW
            )}
            handelSlider={handelSlider}
            currentPage={currentPage}
          />

          <NavigationContainer>
            <Pagination
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </NavigationContainer>
        </Content>
      </ContentWraper>
    </SearchWraper>
  )
}
