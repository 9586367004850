import styled, { keyframes } from 'styled-components'
import media from 'styles/media'

import { IconButton } from 'components/atoms/Button'
import { Container } from 'components/atoms/Container'

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  min-width: 320px;
  min-height: 272px;
`

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
`

export const IconWraper = styled.button`
  cursor: pointer;

  padding: 20px;

  background-color: transparent;
  border: none;
  outline: none;

  ${media.lg.max} {
    padding: 6px;
  }
`

export const CenterImageWrapper = styled.div``

// export const FadeOutBackgroundWrapper = styled.div`
//   width: 100%;
//   height: 100%;

//   animation: ${fadeOut} 1s forwards;

//   & .gatsby-image-wrapper {
//     width: 100%;
//     height: 100%;
//   }
// `

export const FadeOutImageWrapper = styled.div`
  position: absolute;
  animation: ${fadeOut} 1s forwards;

  z-index: 2;
  opacity: 1;
`

export const ImageWraper = styled.div`
  width: 100%;
  min-width: 320px;
  min-height: 272px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  flex: 1;
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  top: 0px;

  transform: translate(50%, -50%);

  z-index: 4;

  ${media.sm.max} {
    right: 24px;
    top: 24px;
    transform: translate(0, 0);
  }
`

export const ImageView = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.7);

  z-index: 99999;
`

export const ImageViewContent = styled(Container)`
  height: 100%;

  user-select: none;
  z-index: 26;

  max-width: 1440px;

  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 140px;

  ${media.xxl.max} {
    gap: 70px;
  }
  ${media.xl.max} {
    gap: 40px;
  }
  ${media.lg.max} {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }
  ${media.md.max} {
    padding-left: 40px;
    padding-right: 40px;
  }
  ${media.sm.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
`
