import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { ReactComponent as Lens } from 'assets/icons/lens.svg'
import { ImageType } from 'types/image'
import { LazyImage } from 'components/atoms/Image'

const ImagesContainerWraper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1136px;

  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

  ${media.xxl.max} {
    gap: 20px;
  }
  ${media.lg.max} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.sm.max} {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
`

const ImageWraper = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 100%;

  margin: 0px;

  object-fit: cover;
  transition: filter 0.2s ease;
`

const ImageHoverContainer = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
`

const LensContaier = styled.div`
  width: 44px;
  height: 44px;

  padding: 10px;

  background-color: #3da544;
  border-radius: 50%;

  opacity: 0;
  z-index: 2;
`

const ImageContent = styled.div`
  cursor: pointer;
  width: 100%;
  height: 300px;
  max-width: 352px;

  margin: 0px auto;

  position: relative;

  &:hover ${Image} {
    filter: grayscale(100%) brightness(0.6);
  }

  &:hover ${LensContaier} {
    opacity: 1;
  }
`

export const ImagesContainer = ({
  images,
  handelSlider,
  currentPage,
}: {
  images: Array<ImageType>
  handelSlider: (index: number) => void
  currentPage: number
}) => {
  return (
    <ImagesContainerWraper>
      {images.map((item, index) => {
        const pageIndex = currentPage - 1
        const bla = pageIndex * 9
        const imageIndex = bla + index
        return (
          <ImageContent
            onClick={() => {
              handelSlider(imageIndex)
            }}
          >
            <ImageHoverContainer>
              <LensContaier>
                <Lens />
              </LensContaier>
            </ImageHoverContainer>
            <ImageWraper>
              <LazyImage
                src={item.src}
                alt={item.alt!}
                fill
                objectFit="cover"
              />
            </ImageWraper>
          </ImageContent>
        )
      })}
    </ImagesContainerWraper>
  )
}
